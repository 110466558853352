import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  units: "metric"
};

export type SettingsType = typeof initialState;

const Settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      return state;
    },
    
    setUnits:   (state, action: PayloadAction<string>) => {
        state.units = action.payload;
        return state;
    },
  },
});

export const {
   setUnits, setToken
} = Settings.actions;

export default Settings;
