import React from 'react';
import tw from '../lib/tailwind';
import { ImageBackground, View } from 'react-native';
import { Text } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import i18n from 'i18n-js';
import { data } from "../data";
import AppButton from "../components/AppButton";

export default function Checkout({ navigation }: RootTabScreenProps<'Checkout'>) {
  return (
    <View style={tw`bg-green-dark pt-8`}>
      <Text style={tw`text-white text-4xl font-sans mb-10 px-8`}>
        {i18n.t('checkout.title')}
      </Text>

      <View style={tw`bg-cream p-8 h-full rounded-t-3xl`}>
        <Text style={tw`text-base font-sans`}>
          {i18n.t('checkout.check_out_time')}
        </Text>
        <Text style={tw`text-2xl font-sans mb-10`}>
          {data.guest.stay.checkOutTime}
        </Text>

        <ImageBackground
          style={tw`w-80 h-80 mb-4 mx-auto`}
          source={require('../assets/images/key.png')}
        />

        <View style={tw`mt-12`}>
          <AppButton
            onPress={() => {
            }}
            title={i18n.t('checkout.check_out_cta')}
            type="primary"
            style="mb-3"
            size="lg"
          />
          <AppButton
            onPress={() => {
            }}
            title={i18n.t('checkout.late_check_out_cta')}
            type="secondary"
            size="lg"
          />
        </View>
      </View>

    </View>
  );
}