import React, { useEffect, useRef, useState } from 'react';
import tw from '../lib/tailwind';
import { RootTabScreenProps, WeatherPayload } from '../types';
import { ImageBackground, ScrollView, TouchableOpacity, View } from 'react-native';
import { Text } from '../components/Themed';
import i18n from 'i18n-js';
import { getWeather } from "../api";
import { useAppSelector } from '../redux';
import { Ionicons } from '@expo/vector-icons';
import { SceneTile } from "../components/SceneTile";
import { DeviceTile } from "../components/DeviceTile";
import { DoorTile } from "../components/DoorTile";

const roomImages = {
  rm1: require('../assets/images/rooms/rm1.jpg'),
  rm2: require('../assets/images/rooms/rm2.jpg'),
  rm3: require('../assets/images/rooms/rm3.jpg'),
  rm4: require('../assets/images/rooms/rm4.jpg'),
  rm5: require('../assets/images/rooms/rm5.jpg'),
  rm6: require('../assets/images/rooms/rm6.jpg'),
  rm7: require('../assets/images/rooms/rm7.jpg'),
  rm8: null,
  rm9: null,
}

const sceneImages = {
  morning: require("../assets/icons/morning.png"),
  daytime: require("../assets/icons/daytime.png"),
  evening: require("../assets/icons/evening.png"),
}


export default function Dashboard({ navigation, route }: RootTabScreenProps<'Dashboard'>) {
  const scroll = useRef<ScrollView>(null);
  const { room, reservation } = route.params;
  const settings = useAppSelector((state) => state.settings);
  const { token } = settings
  const [weather, setWeather] = useState<WeatherPayload>();

  useEffect(() => {
    if ( !room ) {
      navigation.navigate('Rooms')
    }

    getWeather(token).then(setWeather)
  }, [room])

  return (
    <ScrollView>
      <View style={tw.style(`h-55 w-full`, `bg-${room?.tailwind + '-600' || "green-dark"}`)}>
        <ImageBackground
          style={tw.style(`h-65 w-full w-auto`, { resizeMode: 'center', justifyContent: 'center' })}
          source={roomImages[room.id]}
        >
          <View
            style={tw`bg-${room?.tailwind + '-600' || "green-dark"}/60 h-72 w-full flex items-start justify-center px-8 pt-6`}>
            <TouchableOpacity
              style={tw`mb-8 flex flex-row items-center`}
              onPress={() => navigation.navigate("Rooms")}
            >
              <Ionicons name="arrow-back" style={tw`text-white mr-1 text-3xl`}/>
            </TouchableOpacity>
            <View style={tw`flex flex-row items-center justify-center w-full`}>
              <View style={tw`grow-1`}>
                <Text style={tw`text-white text-3xl font-sans`}>
                  {i18n.locale === 'fr' ? room?.name?.fr : room?.name?.en}
                </Text>
                <Text style={tw`text-white text-lg font-sans`}>
                  {i18n.t('rooms.room')} {room.numericId}
                </Text>
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
      <View style={tw`bg-${room?.tailwind + '-600' || "green-dark"} flex-1 px-8 pb-10 rounded-t-4xl min-h-full`}>
        <Text style={tw`text-white text-lg font-sans mt-5 mb-3`}>
          {i18n.t('door.doors')}
        </Text>
        <View style={tw`mb-1`}>
          <View style={tw`flex flex-row`}>
            <View style={tw`flex-auto mr-2`}>
              <DoorTile title={i18n.t('door.entrance')} id="rm8" color="floriel"/>
            </View>
            <View style={tw`flex-auto ml-2`}>
              <DoorTile title={i18n.t('door.parking')} id="rm9" color="floriel"/>
            </View>
          </View>
          <DoorTile title={i18n.t('door.room')} id={room?.id} color={room?.tailwind}/>
        </View>

        {/*<Text style={tw`text-white text-lg font-sans mt-5 mb-3`}>*/}
        {/*  {i18n.t('lighting.scenes')}*/}
        {/*</Text>*/}
        {/*<View style={tw`flex flex-row justify-between w-full mb-4`}>*/}
        {/*  <View style={tw`flex-1 mr-2`}>*/}
        {/*    <SceneTile*/}
        {/*      title="Morning"*/}
        {/*      imageSource={sceneImages.morning}*/}
        {/*      width="46px"*/}
        {/*      height="40px"*/}
        {/*    />*/}
        {/*  </View>*/}
        {/*  <View style={tw`flex-1 mx-2`}>*/}
        {/*    <SceneTile*/}
        {/*      title="Daytime"*/}
        {/*      imageSource={sceneImages.daytime}*/}
        {/*      width="46px"*/}
        {/*      height="46px"*/}
        {/*    />*/}
        {/*  </View>*/}
        {/*  <View style={tw`flex-1 ml-2`}>*/}
        {/*    <SceneTile*/}
        {/*      title="Evening"*/}
        {/*      imageSource={sceneImages.evening}*/}
        {/*      width="46px"*/}
        {/*      height="40px"*/}
        {/*    />*/}
        {/*  </View>*/}
        {/*</View>*/}

        {/* <Text style={tw`text-white text-lg font-sans mt-5 mb-3`}>
          {i18n.t('lighting.title')}
        </Text>
        <View style={tw`flex flex-wrap flex-row w-full mb-4 justify-between`}>
          <View style={tw`flex-1 mr-2`}>
            <DeviceTile
              title={i18n.t('lighting.entrance')}
              isOn={true}
              brightness={50}
              type="lamp"
              color={room.color}
            />
          </View>
          <View style={tw`flex-1 ml-2`}>
            <DeviceTile
              title={i18n.t('lighting.bedside')}
              isOn={true}
              brightness={50}
              type="lamp"
              color={room.color}
            />
          </View>
        </View>

        <Text style={tw`text-white text-lg font-sans mb-3`}>
          {i18n.t('thermostat.title')}
        </Text>
        <View style={tw`flex flex-wrap flex-row w-full mb-4 justify-between`}>
          <View style={tw`flex-1 mr-2`}>
            <DeviceTile
              title={i18n.t('thermostat.bathroom')}
              isOn={false}
              temperature={25}
              type="heat"
              color={room.color}
            />
          </View>
          <View style={tw`flex-1 ml-2`}>
            <DeviceTile
              title={i18n.t('thermostat.bedroom')}
              isOn={true}
              temperature={18}
              type="cool"
              color={room.color}
            />
          </View>
        </View> */}
      </View>
    </ScrollView>
  );
}