import { Linking, Platform } from "react-native";
import * as Localization from 'expo-localization';


export const NavigateToHotel = () =>{
    const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
    const latLng = `46.6149448,-72.7364205`;
    const label = 'Hôtel Floriel';
    const url = Platform.select({
      ios: `${scheme}${label}@${latLng}`,
      android: `${scheme}${latLng}(${label})`
    });

    Linking.openURL(url || '');

}


export const TextFloriel = (initialText?:string) =>{
  const prefix = `sms:+18198056696`
  const url = !!initialText ? `${prefix}?&body=${initialText}` : prefix;
  console.log(url);
  Linking.openURL(url || '');
}

export const TemperatureAndUnit = (temperature:number) =>{
    if (!Localization.isMetric){
      temperature = celsiusToFahrenheit(temperature);
      return `${temperature}°F`;
    } else {
      return `${temperature}°C`;
    }
}

export const celsiusToFahrenheit = (celsius:number) =>{
  return Math.round(celsius * 9/5 + 32);
}

export const fahrenheitToCelsius = (fahrenheit:number) =>{
  return Math.round((fahrenheit - 32) * 5/9 *10)/10;
}