import { Text, TouchableOpacity } from "react-native";
import tw from "../lib/tailwind";

export type AppButtonProps = {
  onPress: () => void,
  title: string,
  type: "primary" | "secondary" | "white",
  size: "lg" | "sm",
  subtitle?: string,
  style?: string,
  tailwindColor?: string,
};

const AppButton = (props: AppButtonProps) => {
  const { onPress, title, type, size, subtitle, style } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={tw`${size === "lg" ? "py-5 px-4" : "p-4"} rounded-xl ${type === 'primary' ? 'bg-green-dark' : type === 'secondary' ? 'border border-green-dark' : 'border border-white'} ${style || ""}`}
    >
      <Text
        style={tw`text-base text-center font-nunitobold ${(type === 'primary' || type === 'white') ? 'text-white' : 'text-green-dark'}`}>
        {title}
      </Text>
      {subtitle &&
        <Text
          style={tw`font-sans text-center text-xs ${(type === 'primary' || type === 'white') ? 'text-white' : 'text-green-dark'}`}>
          {subtitle}
        </Text>
      }
    </TouchableOpacity>
  );
};

export default AppButton;