import { WeatherPayload } from "./types";

const API_URL = 'https://api.floriel.cloud/iot';



export const getReservation = async (token: string) => {
    if (!token) throw new Error('missing token');

    let url = `${API_URL}/${token}`

    return await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.json()).then(res => {console.log(res); return res});;
}

export const getWeather = async (token: string): Promise<WeatherPayload> => {
    if (!token) throw new Error('missing token');

    let url = `${API_URL}/${token}/weather`

    return await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.json()).then(res => {console.log(res); return res});
}

export const unlockDoor = async(token: string, door: string): Promise<boolean> =>{
    if (!token) throw new Error('missing token');

    let url = `${API_URL}/${token}/door/${door}`

    return await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.json()).then(res => res.success).catch(err => false);
}