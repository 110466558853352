import { TouchableOpacity } from 'react-native';
import { Text, View } from '../components/Themed';
import tw from '../lib/tailwind';
import { RootStackScreenProps } from '../types';
import i18n from "i18n-js";

export default function NotFound({ navigation }: RootStackScreenProps<'NotFound'>) {
  return (
    <View style={tw`flex-1 items-center justify-center p-4`}>
      <Text style={tw`text-xl font-bold font-sans`}>
        {i18n.t('not_found.title')}
      </Text>
      <TouchableOpacity
        onPress={() => navigation.replace('Root')}
        style={tw`mt-4`}
      >
        <Text style={tw`text-sm font-sans`}>
          {i18n.t('not_found.back_to_home')}
        </Text>
      </TouchableOpacity>
    </View>
  );
}