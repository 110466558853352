import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import tw from './lib/tailwind';
import { useDeviceContext } from 'twrnc';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { NunitoSans_400Regular, NunitoSans_600SemiBold, useFonts } from '@expo-google-fonts/nunito-sans';
import { Provider } from "react-redux";
//The following import is just to avoid TypeScript declaration errors.
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import { persistor, store } from "./redux";
import { LogBox } from 'react-native';
import dayjs from "dayjs";
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

//LOCALIZATION
//If adding a language, create a language file then add it here
i18n.translations = {
  en: require("./locales/en.json"),
  fr: require("./locales/fr.json"),
};
/**
 * Language selection logic
 * IF the user has a default language that matches one of the translations keys available, choose that.
 * IF NOT, IF the user has a language that matches one of the translations keys available IN THEIR PREFERRED LOCALE LIST, choose that.
 * IF NOT, fallback to French.
 * Note: We're discarding region preference for now.
 */
LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message

const availableAppLocales = Object.keys(i18n.translations);
const fallbackAppLocale = "en";
const userDefaultLocale = Localization.locale.substring(0, 2);
const userPreferredLocales = Localization.locales.map((locale) =>
  locale.substring(0, 2)
);

if ( availableAppLocales.includes(userDefaultLocale) ) {
  i18n.locale = userDefaultLocale;
} else if (
  userPreferredLocales.some((locale) => availableAppLocales.includes(locale))
) {
  i18n.locale = userPreferredLocales.find((locale) =>
    availableAppLocales.includes(locale)
  ) as string;
} else {
  i18n.locale = fallbackAppLocale;
}

// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;
dayjs.locale(i18n.locale);

export default function App() {
  useDeviceContext(tw);
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  let [fontsLoaded] = useFonts({
    NunitoSans_400Regular,
    NunitoSans_600SemiBold,
  });

  if ( !isLoadingComplete && !fontsLoaded ) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme}/>
            <StatusBar style="light"/>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    );
  }
}
