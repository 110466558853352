const {plugin} = require('twrnc');


module.exports = {
  content: ["./**/*.{html,js,tsx}"],
  plugins: [plugin(({addUtilities}) => {
    addUtilities({
      'bg-transparent': {
        backgroundColor: 'transparent',
      },
      'drop-shadow-2xl': {
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
      },
      'min-h-full': {
        minHeight: '100%',
      },
    })
  })],
  safelist: [{
    pattern: /(bg|text|border)-(cranberry|storm-dust|cutty-sark|pesto|san-juan|toast|avocado)-(50|100|200|400|500|600|800)/
  }],
  theme: {
    colors: {
      'error': {
        DEFAULT: '#b91c1c',
        light: '#f87171',
        dark: '#7f1d1d'
      },
      'grey': {
        DEFAULT: '#636361',
        light: '#e6e6e6',
        medium: '#90908e',
        dark: '#393937',
      },
      'green': {
        DEFAULT: '#828e68',
        light: '#b2be96',
        dark: '#414f46',
      },
      'cream': '#f2f0e2',
      'white': '#ffffff',
      'black': '#000000',
      'floriel': {
        DEFAULT: '#9C663A',
        '50': '#E1C4AD',
        '100': '#DBBA9E',
        '200': '#D0A480',
        '300': '#C58F63',
        '400': '#BA7A45',
        '500': '#9C663A',
        '600': '#734B2B',
        '700': '#4A311C',
        '800': '#22160C',
        '900': '#000000'
      },
      'shark': {
        DEFAULT: '#25282A',
        '50': '#D2D5D8',
        '100': '#C7CBCE',
        '200': '#B2B7BB',
        '300': '#9CA3A8',
        '400': '#868F95',
        '500': '#717B81',
        '600': '#5E666B',
        '700': '#4B5155',
        '800': '#383D40',
        '900': '#25282A'
      },
      'cranberry': {
        DEFAULT: '#DB6178',
        '50': '#FDF7F8',
        '100': '#F9E6EA',
        '200': '#F2C5CD',
        '300': '#EAA3B1',
        '400': '#E38294',
        '500': '#DB6178',
        '600': '#D13351',
        '700': '#A6263E',
        '800': '#781B2D',
        '900': '#4B111C'
      },
      'storm-dust': {
        DEFAULT: '#636361',
        '50': '#BEBEBD',
        '100': '#B4B4B3',
        '200': '#A0A09E',
        '300': '#8C8C8A',
        '400': '#787875',
        '500': '#636361',
        '600': '#474745',
        '700': '#2A2A29',
        '800': '#0E0E0E',
        '900': '#000000'
      },
      'cutty-sark': {
        DEFAULT: '#4F787A',
        '50': '#B3CCCE',
        '100': '#A7C4C6',
        '200': '#8EB4B6',
        '300': '#75A3A6',
        '400': '#5F9093',
        '500': '#4F787A',
        '600': '#395758',
        '700': '#233536',
        '800': '#0D1414',
        '900': '#000000'
      },
      'pesto': {
        DEFAULT: '#877037',
        '50': '#D8C79E',
        '100': '#D2BF90',
        '200': '#C6AE73',
        '300': '#BA9D56',
        '400': '#A48843',
        '500': '#877037',
        '600': '#5F4F27',
        '700': '#372E17',
        '800': '#0F0D06',
        '900': '#000000'
      },
      'san-juan': {
        DEFAULT: '#2C4961',
        '50': '#7FA6C5',
        '100': '#719CBF',
        '200': '#5588B2',
        '300': '#457399',
        '400': '#395E7D',
        '500': '#2C4961',
        '600': '#1A2C3A',
        '700': '#090F14',
        '800': '#000000',
        '900': '#000000'
      },
      'toast': {
        DEFAULT: '#996B64',
        '50': '#E2D5D3',
        '100': '#DAC9C6',
        '200': '#CAB1AE',
        '300': '#BA9A95',
        '400': '#AA827C',
        '500': '#996B64',
        '600': '#77534E',
        '700': '#553C38',
        '800': '#332421',
        '900': '#110C0B'
      },
      'avocado': {
        DEFAULT: '#828E68',
        '50': '#D9DDD1',
        '100': '#CFD4C5',
        '200': '#BCC3AD',
        '300': '#A9B296',
        '400': '#96A17E',
        '500': '#828E68',
        '600': '#646E50',
        '700': '#474D39',
        '800': '#292D21',
        '900': '#0B0C09'
      },
    },
    fontFamily: {
      sans: ['NunitoSans_400Regular', 'sans-serif'],
      nunitobold: ['NunitoSans_600SemiBold', 'sans-serif'],
    },
    extend: {
      spacing: {
        '8xl': '96rem',
        '9xl': '128rem',
      },
      borderRadius: {
        '4xl': '2rem',
      }
    }
  },
}