import { ImageBackground, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { ClassInput } from 'twrnc/dist/esm/types';
import tw from '../lib/tailwind';

export type CardProps = {
  imageSource: ImageSourcePropType;
  height: string;
  width?: string;
  style?: ClassInput
};

export function AppImage(props: CardProps) {
  return (
    <View style={tw.style(`overflow-hidden shadow-lg h-${props.height} w-${props.width || 'auto'}`, props?.style)}>
      <ImageBackground style={styles.image} source={props.imageSource}/>
    </View>
  )
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
})