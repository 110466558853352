import LottieView from 'lottie-react-native';
import AnimatedLottieView from 'lottie-react-native';
import tw from "../lib/tailwind";
import React, { useRef } from "react";
import { Image, Platform, TouchableOpacity, View } from "react-native";
import { useAppSelector } from '../redux';
import { unlockDoor } from '../api';
import i18n from "i18n-js";
import * as Haptics from 'expo-haptics';

const UnlockButton: React.FC<{ roomId: string, defaultColor?: string | undefined }> = ({ roomId, defaultColor }) => {
  const settings = useAppSelector((state) => state.settings);
  const { token } = settings

  const animation = useRef<AnimatedLottieView>(null);
  const [open, setopen] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const unlock = () => {
    setHasError(false);

    unlockDoor(token, roomId).then((success) => {
      if ( success ) {
        setopen(true);
        setHasError(false);

        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success)

        setTimeout(() => {
          animation.current?.play();
        }, 4000)

        setTimeout(() => {
          setopen(false)
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
        }, 4500)

      } else {
        animation.current?.reset();

        setHasError(true);
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error)

        alert(i18n.t("errors.door"))

        setTimeout(() => {
          setHasError(false);
        }, 5000)
      }
    });

    animation.current?.play();
    setTimeout(() => {
      //@ts-ignore
      animation.current.pause();
    }, 500)
  }

  return (
    <TouchableOpacity onPress={unlock}>
      <View style={tw`rounded rounded-full w-14 h-14 overflow-hidden shadow-lg`}>
        {Platform.select({
          native: <LottieView
            autoPlay={false}
            loop={false}
            ref={animation}
            style={tw.style(`w-full h-full`, hasError ? 'bg-error' : (open ? 'bg-green' : (('bg-' + defaultColor + '-500') || 'bg-floriel-500')))}
            source={require('../assets/lottie/lock.json')}/>,
          default: <View
            style={tw.style(`w-16 h-16`, hasError ? 'bg-error' : (open ? 'bg-green' : (('bg-' + defaultColor + '-500') || 'bg-floriel-500')))}>
            <Image style={tw`w-12 h-12 ml-1 mt-1` }
            resizeMode="contain"
                   source={open ? require('../assets/images/unlocked.png') : require('../assets/images/locked.png')}/>
          </View>
        })}
      </View>
    </TouchableOpacity>
  );
}

export default UnlockButton;