import { ImageSourcePropType, Pressable, Text, View } from 'react-native';
import tw from '../lib/tailwind';
import { AppImage } from "./AppImage";
import dayjs from "dayjs";
import i18n from "i18n-js";
import AppButton from "./AppButton";
import React from "react";
import { RoomData } from "../types";
import UnlockButton from './UnlockButton';
import { useAppSelector } from '../redux';
import { unlockDoor } from '../api';

const roomImages = {
  rm1: require('../assets/images/rooms/rm1.jpg'),
  rm2: require('../assets/images/rooms/rm2.jpg'),
  rm3: require('../assets/images/rooms/rm3.jpg'),
  rm4: require('../assets/images/rooms/rm4.jpg'),
  rm5: require('../assets/images/rooms/rm5.jpg'),
  rm6: require('../assets/images/rooms/rm6.jpg'),
  rm7: require('../assets/images/rooms/rm7.jpg'),
  rm8: null,
  rm9: null,
}

interface CardProps {
  room: RoomData,
  imageSource: ImageSourcePropType,
  onPress: () => void,
}

export function RoomTile(props: CardProps) {
  const { room, onPress } = props;
  const imageSource = roomImages[room.id || "rm1"];

  const settings = useAppSelector((state) => state.settings);
  const { token } = settings

  const unlockRoom = () => {
    unlockDoor(token, room.id).then((success) => {
      if ( !success ) {
        alert(i18n.t("errors.door"))
      }
    })
  }

  return (
    <View style={tw`p-6 mb-2`}>
      <Pressable onPress={() => {room.active && onPress()}}>
        <View style={tw`absolute bottom-3 right-3 z-10`}>
          <UnlockButton roomId={room.id} defaultColor={room.tailwind}/>
        </View>
        <AppImage
          imageSource={imageSource}
          height="50"
          style={tw`rounded-3xl`}
        />
      </Pressable>
      <View style={tw`flex flex-row items-end pt-4 `}>
        <View style={tw`flex-4`}>
          <Text style={tw`text-green-dark text-sm font-sans pb-1 pt-4`}>
            {dayjs(room.startDate).format("ll")} - {dayjs(room.endDate).format("ll")}
          </Text>
          <Text style={tw`text-2xl font-sans text-green-dark leading-tight`}>
            {i18n.locale === 'fr' ? room.name.fr : room.name.en}
          </Text>
          <Text style={tw`text-lg font-sans text-green-dark mb-0 pb-0`}>
            {i18n.t('rooms.room')} {room.numericId}
          </Text>
        </View>
        <View style={tw`flex-3`}>
          {room.active ?
            <View>
              <AppButton
                onPress={onPress}
                title={i18n.t("rooms.view")}
                type="secondary"
                size="sm"
                style="self-end"
              />
            </View> :
            <Text style={tw`text-sm font-sans leading-tight text-green-dark`}>
              {i18n.t('rooms.room_availability_start')} {dayjs(room.startDate).subtract(1, 'day').format("LL")} {i18n.t('rooms.room_availability_end')}
            </Text>
          }
        </View>
      </View>
    </View>
  )
}