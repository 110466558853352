// import ClearNight from "../assets/weather/clear-night.svg";
// import Cloudy from "../assets/weather/cloudy.svg";
// import Fog from "../assets/weather/fog.svg";
// import Hail from "../assets/weather/hail.svg";
// import Lightning from "../assets/weather/lightning.svg";
// import LightningRainy from "../assets/weather/lightning-rainy.svg";
// import PartlyCloudy from "../assets/weather/partlycloudy.svg";
// import Rainy from "../assets/weather/rainy.svg";
// import Pouring from "../assets/weather/pouring.svg";
// import Snowy from "../assets/weather/snowy.svg";
// import SnowyRainy from "../assets/weather/snowy-rainy.svg";
// import Sunny from "../assets/weather/sunny.svg";
// import Windy from "../assets/weather/windy.svg";
// import WindyVariant from "../assets/weather/windy-variant.svg";
// import Exceptional from "../assets/weather/exceptional.svg";
// import { WeatherCondition } from "../types";

// const svgs = {
//   "clear-night": ClearNight,
//   cloudy: Cloudy,
//   fog: Fog,
//   hail: Hail,
//   lightning: Lightning,
//   "lightning-rainy": LightningRainy,
//   partlycloudy: PartlyCloudy,
//   rainy: Rainy,
//   pouring: Pouring,
//   snowy: Snowy,
//   "snowy-rainy": SnowyRainy,
//   sunny: Sunny,
//   windy: Windy,
//   "windy-variant": WindyVariant,
//   exceptional: Exceptional,
// };

const ClearNight = require("../assets/weather/clear-night.svg.png");
const Cloudy = require("../assets/weather/cloudy.svg.png");
const Fog = require("../assets/weather/fog.svg.png");
const Hail = require("../assets/weather/hail.svg.png");
const Lightning = require("../assets/weather/lightning.svg.png");
const LightningRainy = require("../assets/weather/lightning-rainy.svg.png");
const PartlyCloudy = require("../assets/weather/partlycloudy.svg.png");
const Rainy = require("../assets/weather/rainy.svg.png");
const Pouring = require("../assets/weather/pouring.svg.png");
const Snowy = require("../assets/weather/snowy.svg.png");
const SnowyRainy = require("../assets/weather/snowy-rainy.svg.png");
const Sunny = require("../assets/weather/sunny.svg.png");
const Windy = require("../assets/weather/windy.svg.png");
const WindyVariant = require("../assets/weather/windy-variant.svg.png");
const Exceptional = require("../assets/weather/exceptional.svg.png");

import { WeatherCondition } from "../types";
import {Image} from 'react-native';
import tw from "../lib/tailwind";

const svgs = {
  "clear-night": ClearNight,
  cloudy: Cloudy,
  fog: Fog,
  hail: Hail,
  lightning: Lightning,
  "lightning-rainy": LightningRainy,
  partlycloudy: PartlyCloudy,
  rainy: Rainy,
  pouring: Pouring,
  snowy: Snowy,
  "snowy-rainy": SnowyRainy,
  sunny: Sunny,
  windy: Windy,
  "windy-variant": WindyVariant,
  exceptional: Exceptional,
};


const WeatherIcon: React.FC<{ condition: WeatherCondition, customColor?: string }> = ({
  condition, customColor
}) => {
  const WeatherSvg = svgs[condition];
  console.log(WeatherSvg);
  if (!WeatherSvg) return <></>;
  return <Image source={WeatherSvg} style={tw`w-12 h-12`}/>
};

export default WeatherIcon;
