import { Image, Linking, Platform, TouchableOpacity, View } from 'react-native';
import { Text } from '../components/Themed';
import { ResizeMode, Video } from 'expo-av';
import tw from '../lib/tailwind';
import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { BarCodeScanner, BarCodeScannerResult } from 'expo-barcode-scanner';
import { RootTabScreenProps } from "../types";
import { useAppDispatch, useAppSelector } from '../redux';
import { setToken } from '../redux/Settings';
import Dialog from "react-native-dialog";
import AppButton from "../components/AppButton";
import Ionicons from "@expo/vector-icons/Ionicons";
import { ScrollView } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';

export default function ReservationLookup({ navigation }: RootTabScreenProps<'Modal'>) {
  const dispatch = useAppDispatch();
  const settings = useAppSelector((state) => state.settings);
  const { token } = settings;
  const [promptVisible, setPromptVisible] = useState(false);
  const [scanCodes, setScanCodes] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");

  const handleCancel = () => {
    setPromptVisible(false);
  };

  const showPrompt = () => {
    setPromptVisible(true);
  }

  const startScanningCodes = async() => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    if ( status === 'granted' ) {
      setScanCodes(true);
    }
    setTimeout(() => {
      setScanCodes(false)
    }, 30 * 1000)
  }

  const processBarcode = (result: BarCodeScannerResult) => {
    const qr = result?.data;

    if ( !qr ) return;
    if ( !qr.startsWith('https://app.floriel.cloud/qr')
      && !qr.startsWith('floriel://') ) return;

    setScanCodes(false);

    const token = qr.replace('https://app.floriel.cloud/qr', '').replace('floriel://', '');

    if ( token ) {
      dispatch(setToken(token));
    }
  }

  const promptForToken = () => {
    Platform.select({
      native: () => {
        showPrompt();
      },
      default: () => {
        const token = prompt(i18n.t('modal.key'))
        if ( token ) {
          dispatch(setToken(token));
        }
      }
    })();
  }

  useEffect(() => {
    if ( token ) {
      setTimeout(() => {
        navigation.navigate("Rooms")
      }, 500)
    }
  }, [token])
  return (
    <View style={tw`bg-green-dark`}>
      {Platform.OS != "web" && <Dialog.Container visible={promptVisible}>
        <Dialog.Description>
          {i18n.t('modal.key')}
        </Dialog.Description>
        <Dialog.Input autoCapitalize='none' onChangeText={(value: string) => setInputValue(value)}/>
        <Dialog.Button label="Cancel" onPress={handleCancel}/>
        <Dialog.Button label="OK" onPress={() => {
          if ( inputValue ) {
            dispatch(setToken(inputValue));
          }
        }}/>
      </Dialog.Container>}

      <Video
        style={tw`w-[100vw] h-[30vh] web:h-[60vh] absolute top-0 left-0 bg-[#52a19d] opacity-${scanCodes ? 0 : 100}`}
        source={{
          uri: 'https://customer-edjxqxkc1913xx3o.cloudflarestream.com/4e9a1241c6ec0d70ac102b2aeb4ed2a2/manifest/video.m3u8',
        }}
        resizeMode={ResizeMode.COVER}
        shouldPlay={true}
        isLooping={true}
        isMuted={true}
      />

      {scanCodes &&
        <BarCodeScanner
          style={tw`absolute top-0 left-0 right-0 bottom-0 w-[100vw] h-[100vh]`}
          barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
          onBarCodeScanned={processBarcode}
        />
      }

      <View
        style={tw`w-[100vw] h-[100vh] absolute bg-[${scanCodes ? '#52a19d80' : '#00000080'}]`}>
        <Image
          source={require('../assets/images/floriel_full_logo.png')}
          resizeMode="contain"
          style={tw`mx-auto -mb-20 h-80 w-32`}
        />

        {scanCodes ?
          <View style={tw`bottom-24 absolute w-full`}>
            <TouchableOpacity
              onPress={() => setScanCodes(false)}
              style={tw`bg-white rounded-full p-2 mx-auto drop-shadow-2xl w-12 h-12`}
            >
              <Ionicons size={32} name="close-outline"/>
            </TouchableOpacity>
          </View>
          : <ScrollView
            style={tw`bg-cream rounded-t-4xl flex h-full pt-8 px-6`}
          >
            <View style={tw`flex items-center mb-24`}>
              <Text style={tw`text-green-dark font-nunitobold text-2xl mb-2`}>
                {i18n.t("modal.welcome")}
              </Text>

              <Text style={tw`font-sans text-base mb-4 text-green-dark text-center`}>
                {i18n.t("modal.choose")}
              </Text>

              {Platform.OS != "web" && <AppButton
                onPress={() => startScanningCodes()}
                title={i18n.t("modal.scan")}
                size="sm"
                type="primary"
                style="mb-4 w-full max-w-96"
              />}

              <AppButton
                onPress={promptForToken}
                title={i18n.t("modal.key")}
                size="sm"
                type="secondary"
                style="w-full max-w-96"
              />
              <Text style={tw`font-sans text-sm mb-4 text-center mt-2 mx-2 text-green-dark max-w-96`}>
                {i18n.t("modal.concierge_key")}
              </Text>

              <Text
                style={tw`font-sans text-base underline mt-5 text-green-dark`}
                onPress={() => Linking.openURL("https://www.hotelfloriel.com")}
              >
                {i18n.t("modal.reservation")}
              </Text>
            </View>
          </ScrollView>
        }
      </View>
    </View>
  );
}
