import { Text, View } from 'react-native';
import tw from '../lib/tailwind';
import i18n from "i18n-js";
import React from "react";
import UnlockButton from './UnlockButton';

interface CardProps {
  title: string,
  id: string,
  color?: string,
}

export function DoorTile(props: CardProps) {
  const { title, id, color } = props;

  return (
    <View
      style={tw`rounded-xl shadow-lg bg-cream px-4 py-3  mb-4`}>
      <View style={tw`flex flex-row justify-between items-end`}>
        <View>
          <Text style={tw`text-base font-sans text-green-dark`}>
            {i18n.t('door.title')}
          </Text>
          <Text style={tw`text-2xl font-sans text-green-dark`}>
            {title}
          </Text>
        </View>
        <UnlockButton roomId={id} defaultColor={color}/>
      </View>
    </View>
  )
}