export const data =
  {
    guest : {
      firstName: "Jane",
      lastName: "Doe",
      email: "jane@test.com",
      stay: {
        checkIn: "2022-12-06",
        checkOut: "2022-15-06",
        checkOutTime: "11:00",
        numberOfGuests: 2,
        numberOfRooms: 1,
        roomId: "3",
        roomName: "L’âme de la forêt"
      }
    },
    rooms: [
      {
        id: "1",
        name: "Coeur en fleurs",
        bgColor: "pink-dark",
        bgColorHex: "#732333",
        accentColor: "pink",
        accentColorHex: "#ef5675",
      },
      {
        id: "2",
        name: "L’âme de la forêt",
        bgColor: "green-dark",
        bgColorHex: "#414f46",
        accentColor: "green",
        accentColorHex: "#828e68",
        lights: [
          {
            id: "1",
            name: "Pendant light",
            image: "../assets/images/lighting.png",
            isOn: true,
            brightness: 50
          },
          {
            id: "2",
            name: "Bedside lamp",
            image: "../assets/images/lighting.png",
            isOn: true,
          brightness: 25
          },
          {
            id: "3",
            name: "Desk lamp",
            image: "../assets/images/lighting.png",
            isOn: true,
            brightness: 75
          }
        ]
      },
      {
        id: "3",
        name: "Contes et légendes",
        bgColor: "blue-dark",
        bgColorHex: "#2b4961",
        accentColor: "teal",
        accentColorHex: "#4f787a",
      },
      {
        id: "4",
        name: "Le vent dans les blés",
        bgColor: "gold-dark",
        bgColorHex: "#8c7b4e",
        accentColor: "gold",
        accentColorHex: "#ceac62",
      },
      {
        id: "5",
        name: "Brume sur le Lac",
        bgColor: "grey-dark",
        bgColorHex: "#636361",
        accentColor: "blue-dark",
        accentColorHex: "#2b4961",
      },
      {
        id: "6",
        name: "Hymne au Printemps",
        bgColor: "blue-dark",
        bgColorHex: "#2b4961",
        accentColor: "blue-light",
        accentColorHex: "#88c7d6",
      },
      {
        id: "7",
        name: "Blanche des Neiges",
        bgColor: "grey-dark",
        bgColorHex: "#636361",
        accentColor: "grey-light",
        accentColorHex: "#e6e6e6",
      }
    ]
  }