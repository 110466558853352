import React from 'react';
import tw from '../lib/tailwind';
import { ScrollView, View } from 'react-native';
import { Text } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { data } from '../data';
import i18n from 'i18n-js';

export default function Settings({ navigation }: RootTabScreenProps<'Settings'>) {
  return (
    <ScrollView style={tw`bg-green-dark py-8`}>
      <Text style={tw`text-white text-4xl font-sans mb-10 px-8`}>
        {i18n.t('settings.title')}
      </Text>

      <View style={tw`bg-cream p-8 h-screen rounded-t-3xl`}>
        <Text style={tw`text-base font-sans`}>
          {i18n.t('settings.name')}
        </Text>
        <Text style={tw`text-2xl font-sans mb-10`}>
          {data.guest.firstName} {data.guest.lastName}
        </Text>

        <Text style={tw`text-base font-sans`}>
          {i18n.t('settings.room_name')}
        </Text>
        <Text style={tw`text-2xl font-sans mb-10`}>
          {data.guest.stay.roomName}
        </Text>

        <Text style={tw`text-base font-sans`}>
          {i18n.t('settings.check_in')}
        </Text>
        <Text style={tw`text-2xl font-sans mb-10`}>
          {data.guest.stay.checkIn}
        </Text>

        <Text style={tw`text-base font-sans`}>
          {i18n.t('settings.check_out')}
        </Text>
        <Text style={tw`text-2xl font-sans mb-10`}>
          {data.guest.stay.checkOut}
        </Text>
      </View>

    </ScrollView>
  );
}