/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import * as React from 'react';
import { ColorSchemeName } from 'react-native';
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ReservationLookup from '../screens/ReservationLookup';
import NotFound from '../screens/NotFoundScreen';
import Dashboard from '../screens/Dashboard';
import { RootStackParamList, RootTabParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import Settings from "../screens/Settings";
import Checkout from "../screens/Checkout";
import RoomSelection from '../screens/Rooms';

export default function Navigation({colorScheme}: { colorScheme: ColorSchemeName }) {
  return (
      <NavigationContainer
          linking={ LinkingConfiguration }
          theme={ colorScheme === 'dark' ? DarkTheme : DefaultTheme }>
        <RootNavigator/>
      </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
      <Stack.Navigator>
        <Stack.Screen
            name="Root"
            component={ DrawerNavigator }
            options={ {headerShown: false} }
        />
        <Stack.Screen
            name="NotFound"
            component={ NotFound }
            options={ {title: '404'} }
        />
        <Stack.Group screenOptions={ {presentation: 'fullScreenModal'} }>
          <Stack.Screen
              name="Modal"
              component={ ReservationLookup }
              options={ {headerShown: false} }
          />
        </Stack.Group>
      </Stack.Navigator>
  );
}

const MainNav = createNativeStackNavigator<RootTabParamList>();

function DrawerNavigator() {
  return <MainNav.Navigator
      initialRouteName='Rooms'
      screenOptions={ () => ({
        headerStyle: {
          backgroundColor: '#414f46',
        },
        drawerLabelStyle: {
          marginLeft: -15,
          fontFamily: 'NunitoSans_400Regular'
        },
        drawerActiveBackgroundColor: '#f2f0e2',
        drawerActiveTintColor: '#000',
        headerTintColor: '#fff',
        headerTitle: '',
        headerTransparent: true,
        headerShown: false,
      }) }
  >
    <MainNav.Screen
        name="Rooms"
        component={ RoomSelection }
        options={ ({navigation}) => ({
          title: 'Rooms',
          headerShown: false
        }) }
    />
    <MainNav.Screen
        name="Dashboard"
        component={ Dashboard }
        options={ ({navigation}) => ({
          title: 'Dashboard',
          headerTransparent: true,
          headerShown: false,
        }) }
    />
    <MainNav.Screen
        name="Settings"
        component={ Settings }
        options={ {
          title: 'Settings',
        } }
    />
    <MainNav.Screen
        name="Checkout"
        component={ Checkout }
        options={ {
          title: 'Check-out',
        } }
    />
  </MainNav.Navigator>
}
