import React, { useEffect, useState } from "react";
import tw from "../lib/tailwind";
import { RootTabScreenProps, WeatherPayload } from "../types";
import { Alert, Image, Platform, Pressable, RefreshControl, ScrollView, View, } from "react-native";
import { Text } from "../components/Themed";
import i18n from "i18n-js";
import { getReservation, getWeather } from "../api";
import { RoomTile } from "../components/RoomTile";
import { useAppDispatch, useAppSelector } from "../redux";
import WeatherIcon from "../components/WeatherIcon";
import { Ionicons } from "@expo/vector-icons";
import { NavigateToHotel, TemperatureAndUnit, TextFloriel } from "../lib/utils";
import { TouchableOpacity } from "react-native-gesture-handler";
import * as Linking from 'expo-linking';
import { setToken } from "../redux/Settings";
import AppButton from "../components/AppButton";
import { DoorTile } from '../components/DoorTile'

export default function RoomSelection({ navigation }: RootTabScreenProps<"Rooms">) {
  const dispatch = useAppDispatch();

  const settings = useAppSelector((state) => state.settings);
  const { token } = settings;

  const [roomData, setRoomData] = useState<any>();
  const [weather, setWeather] = useState<WeatherPayload>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const { guestName, rooms } = roomData || {};
  const orderedRooms = rooms?.sort((a: any, b: any) =>
    a.active ? 1 : b.startDate > a.startDate ? -1 : 0
  );
  const hasActiveRoom = rooms?.filter((room: any) => !!room.active).length > 0;
  const placeholderImage = require("../assets/images/room-placeholder.jpg");
  const url = Linking.useURL()

  useEffect(() => {
    if ( url?.includes("tk/") ) {
      const urlToken = url.split("tk/")[1]
      dispatch(setToken(urlToken));
      setTimeout(()=> { refresh()}, 500)
     
    }
  }, [url])

  useEffect(() => {
    if ( !settings || !url ) return;
    if ( !token && !url.includes("tk/") ) {
      navigation.navigate("Modal");
    } else {
      setTimeout(()=> { refresh()}, 500)
    }
  }, [settings, url]);

  const eraseToken = () => {
    Platform.select({
      native: () =>
        Alert.alert(
          "Are you sure you want to erase your token?",
          undefined, [{
            text: "Yes",
            onPress: () => {
              dispatch(setToken(""));
              navigation.navigate("Modal");
            },
            style: "destructive"
          },
            {
              text: "No",
              style: "cancel"
            }
          ]
        ),
      default: () => {
        const erase = confirm("Are you sure you want to erase your token?");
        if ( erase ) {
          dispatch(setToken(""));
        }
      }
    })();
  }

  const refresh = () => {
    setRefreshing(true);
    getReservation(token)
      .then((res) =>
        setRoomData(res)
      )
      .then(() => setRefreshing(false));
    getWeather(token).then(setWeather);
  };

  return (
    <ScrollView
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={refresh} colors={['white']} tintColor="white"/>
      }
      style={tw`bg-green-dark pt-14 flex -mb-4`}
    >
      <View style={tw`px-8`}>
        <Image
          style={tw`w-24 h-10 mb-2`}
          source={require("../assets/images/floriel-logo.png")}
        />
        <Text style={tw`text-white text-3xl font-sans`}>
          {guestName ? (
            <>
              {i18n.t("rooms.welcome")}
              {guestName && `, ` + guestName.split(" ")[0]}
            </>
          ) : (
            <>{i18n.t("modal.welcome")}</>
          )}
        </Text>
        {orderedRooms && hasActiveRoom && (
          <Text style={tw`text-white text-base font-sans`}>
            {i18n.t("rooms.welcome_subtitle")}
          </Text>
        )}
      </View>

      <View
        style={tw`bg-cream rounded-t-4xl mt-6 mb-18 flex min-h-full`}
      >
        {orderedRooms && hasActiveRoom && (
          <View style={tw`px-6 pt-6 mb-0`}>
            <View style={tw`flex flex-row`}>
              <View style={tw`flex-auto mr-2`}>
                <DoorTile title={i18n.t('door.entrance')} id="ex1" color="floriel"/>
              </View>
              <View style={tw`flex-auto ml-2`}>
                <DoorTile title={i18n.t('door.parking')} id="ex2" color="floriel"/>
              </View>
            </View>
          </View>
        )}

        {orderedRooms &&
          orderedRooms.map((room: any, index: number) => (
            <RoomTile
              key={index}
              room={room}
              imageSource={placeholderImage}
              onPress={() =>
                navigation.navigate("Dashboard", {
                  room,
                  reservation: roomData,
                })
              }
            />
          ))}

        {!orderedRooms && (
          <View
            style={tw`flex justify-center items-center h-48 px-12 text-center`}
          >
            <Text style={tw`text-green-dark text-lg font-sans text-center mb-4`}>
              {i18n.t("rooms.no_rooms")}
            </Text>
            <AppButton
              onPress={() => {
                dispatch(setToken(""));
                navigation.navigate("Modal")
              }}
              title={i18n.t('rooms.clear_token')}
              size="sm"
              type="secondary"
              style="w-84"
            />
          </View>
        )}

        <View
          style={tw`my-4 mt-4 mx-6 border-t border-green pt-10`}
        >
          <Text style={tw`text-base font-sans text-green-dark w-full mb-1`}>
            {i18n.t("rooms.our_address")}
          </Text>

          <Text style={tw`text-2xl font-sans w-full text-green-dark`}>
            3311 Chemin de Ste-Flore, Shawinigan QC G9T 1A5
          </Text>

          <TouchableOpacity
            onPress={NavigateToHotel}
            style={tw`bg-transparent mb-6 mt-2 rounded-full flex flex-row items-center`}
          >
            <Ionicons
              name="map-outline"
              style={tw`text-green-dark text-xl mr-2`}
            />
            <Text style={tw`text-green-dark font-sans underline text-base`}>
              {i18n.t(`rooms.get_directions`)}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={tw`flex flex-row mx-6 mb-6`}>
          {weather && (
            <View style={tw`flex-1 pr-2`}>
              <View style={tw`w-full bg-white/50 p-3 rounded-xl`}>
                <View style={tw`w-12 h-12`}>
                  <WeatherIcon condition={weather.conditions.state}/>
                </View>
                <View>
                  <Text style={tw`text-base font-sans text-green-dark`}>
                    {i18n.t(`rooms.weather`)}
                  </Text>
                  <Text style={tw`text-xl font-sans text-green-dark`}>
                    {TemperatureAndUnit(weather.temperature)}
                  </Text>
                </View>
              </View>
            </View>
          )}

          <View style={tw`flex-1 pl-2`}>
            <TouchableOpacity
              onPress={() =>
                TextFloriel(roomData ? `Res ${roomData?.id}\n-\n` : undefined)
              }
              style={tw`w-full bg-white/50 p-3 rounded-xl`}
            >
              <View>
                <Ionicons name="chatbox-ellipses-outline" style={tw`text-3xl text-green-dark mt-2`}/>
              </View>
              <View>
                <Text style={tw`text-base font-sans text-green-dark mt-1`}>
                  {i18n.t(`rooms.need_help`)}
                </Text>
                <Text style={tw`text-xl font-sans text-green-dark`}>
                  {i18n.t(`rooms.contact_us`)}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>

        {roomData?.id &&
          <Pressable style={tw`border-t border-grey-light pt-4 px-6 mt-2 mb-12`} onLongPress={eraseToken}>
            <Text style={tw`text-green-dark font-sans text-sm`}>
              Reservation: {roomData?.id}, {roomData?.guestName}
            </Text>
          </Pressable>
        }
      </View>
    </ScrollView>
  );
}
